import React from 'react';

import Redirect from '../components/Redirect';

import Instagram from '../../assets/Instagram.svg';

export default function Contact(props) {
    return (
        <div className='frame column center offwhite' style={{ gap: '24rem', padding: '80rem' }}>
            <div className='frame column center' style={{ gap: '16rem' }}>
                <p className='font-24 bold'>ORDER CONCERNS</p>
                <a href='mailto:chefskascravings@gmail.com?subject=Customer Support'><p className='font-20 uppercase black'>CHEFSKASCRAVINGS@GMAIL.COM</p></a>
                <p className='font-24 bold'>WEBSITE PROBLEMS</p>
                <a href="mailto: help@chefskascravings.com?subject=Chefska's Cravings Customer Support"><p className='font-20 uppercase black'>HELP@CHEFSKASCRAVINGS.COM</p></a>
                <p className='font-24 bold'>SOCIAL MEDIA</p>
                <Redirect to='https://www.instagram.com/chefskascravings/' external>
                    <div className='frame row center' style={{ gap: '16rem', padding: '0' }}>
                        <img src={Instagram} style={{ width: '24rem', height: '24rem' }}></img>
                        <p className='font-20'>@chefskascravings</p>
                    </div>
                </Redirect>
            </div>
        </div>
    );
}