import React, { useState, useEffect, useReducer, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link, Element, scroller } from 'react-scroll';

import CartContext from '../contexts/CartContext';

import Selector from '../mobileComponents/Selector';
import InfoDrawer from '../mobileComponents/InfoDrawer';
import OrderItem from '../mobileComponents/OrderItem';
import StepSlider from '../mobileComponents/StepSlider';
import Input from '../mobileComponents/Input';
import Checkbox from '../mobileComponents/Checkbox';
import Popup from '../mobileComponents/Popup';

import CheckoutFinalStep from './CheckoutFinalStep';

import ShortOption from '../mobileStyles/ShortOption';
import StandardInput from '../mobileStyles/StandardInput';

import FullHeart from '../../assets/FullHeart.svg';
import RedDiscountTag from '../../assets/RedDiscountTag.svg';
// import ClearDiscountTag from '../../assets/ClearDiscountTag.svg';

import { toLongTime } from '../../functions/Datetimes';

const cookies = new Cookies(null, { path: '/' });

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

function CheckoutHeader(props) {
    const schedule = cookies.get('schedule');
    return (<div className='frame column align-items-start stretch' style={{ gap: '8rem', padding: '0 16rem' }}>
        <p className='font-28 bold'>{props.children}</p>
        <div className='frame' style={{ gap: '8rem' }}>
            {schedule ? <p className='font-12'>
                <span>You scheduled pickup for <span className='primary bold'>{schedule.date} at {schedule.time.hour ? toLongTime(schedule.time) : schedule.time} in {schedule.location}</span>.</span>
            </p> :
                <p className='font-12 primary bold'>Please confirm your pickup date and time before continuing.</p>}
        </div>
    </div>);
}

function Checkout(props) {
    const { cart, handleRemoveFromCart } = useContext(CartContext);
    const order = cart.items;

    let contact = cart.contactInfo;
    if (contact === undefined) {
        contact = {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        };
    }

    let sub = 0;
    for (const item of order) {
        sub += item.receipt.price * item.receipt.quantity;
    }

    let tempQuantities = [];

    const itemFrames = [];
    const miniItemFrames = [];
    for (const item of order) {
        tempQuantities.push(item.quantity);
    }
    const [quantities, setQuantities] = useState(tempQuantities);

    const [step, setStep] = useState('cart');
    const [stepID, setStepID] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState(-1);
    const [edit, setEdit] = useState(false);
    const [subtotal, setSubtotal] = useState(sub);
    const [discount, setDiscount] = useState(0);
    const [tip, setTip] = useState(undefined);
    const [tipOption, setTipOption] = useState(-1);
    const [customPercent, setCustomPercent] = useState('');
    const [customTipSelected, setCustomTipSelected] = useState(false);
    const [customPercentSelected, setCustomPercentSelected] = useState(false);
    const [getUpdates, setGetUpdates] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [discounts, setDiscounts] = useState([]);
    const [discountMessage, setDiscountMessage] = useState('');

    const [firstName, setFirstName] = useState(contact.firstName);
    const [lastName, setLastName] = useState(contact.lastName);
    const [email, setEmail] = useState(contact.email);
    const [phone, setPhone] = useState(contact.phone);

    const [agreement, setAgreement] = useState(false);

    const [confirmError, setConfirmError] = useState('');

    useEffect(() => {
        if (confirmError === '') return;
        const timer = setTimeout(() => {
            setConfirmError('');
        }, 20000);
        return () => clearTimeout(timer);
    }, [confirmError]);

    useEffect(() => {
        console.log('CONFIRM DELETE: ' + confirmDelete);
    }, [confirmDelete]);

    const navigate = useNavigate();

    const toMenu = () => {
        navigate('/menu');
    }

    const toCart = () => {
        navigate('/checkout/cart', { replace: true });
    }

    const toContact = () => {
        navigate('/checkout/contact', { replace: true });
    }

    const toPayment = () => {
        navigate('/checkout/payment', { replace: true });
    }

    const tipOptions = [{ id: 0, name: '5%' }, { id: 1, name: '10%' }, { id: 2, name: '15%' }];

    const taxable = false;
    const tax = 0;
    const [total, setTotal] = useState(subtotal + tax);

    const [clientSecret, setClientSecret] = useState('yerr');

    useEffect(() => {
        if (step === 'payment') {
            handleToContact();
        }
        setClientSecret('yerr');
    }, [total]);

    useEffect(() => {
        switch (step) {
            case 'cart':
                setStepID(0);
                break;
            case 'contact':
                setStepID(1);
                break;
            case 'payment':
                setStepID(2);
                break
        }
        if (step === 'payment') {
            console.log('Order before JSON.stringify:', order);
            console.log('Order after JSON.stringify:' + JSON.stringify(order));
            const orderSend = order.map((it) => it.receipt);
            const scheduleCookie = cookies.get('schedule');
            const requestBody = JSON.stringify({ items: orderSend, tip: (tip ? tip : 0), tax: tax, discount: discount, schedule: scheduleCookie, firstName: firstName, lastName: lastName, email: email, phone: phone });
            // console.log('Request body:');
            // console.log(requestBody);
            fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/create-payment-intent', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": 'application/x-www-form-urlencoded'
                },
                body: requestBody,
                isBase64Encoded: false
            })
                .then((res) => res.json())
                .then((data) => {
                    // console.log('data: '+JSON.stringify(data, null, 4));
                    // console.log('clientSecret'+data.clientSecret);
                    setClientSecret(data.clientSecret);
                });
        }
    }, [step]);

    // console.log('clientSecret: ' + clientSecret);

    const checkoutOptions = {
        // mode: 'payment',
        // amount: parseInt(parseFloat(('' + total).replaceAll(/[^0-9]/g, ''))),
        // currency: 'usd',
        clientSecret,
        // Customizable with appearance API.
        appearance: {
            theme: 'flat',
            variables: {
                fontFamily: ' "DM Sans", sans-serif',
                fontLineHeight: '1.5',
                borderRadius: '10px',
                colorBackground: '#FFFFFF',
                accessibleColorOnColorPrimary: '#FF5271',
                colorDanger: '#FF5271'
            },
            rules: {
                '.Block': {
                    backgroundColor: 'var(--colorBackground)',
                    boxShadow: 'none',
                    padding: '12px'
                },
                '.Input': {
                    padding: '12px',
                    border: '1.5px solid #DCD2CC'
                },
                '.Input:disabled, .Input--invalid:disabled': {
                    color: 'lightgray'
                },
                '.Tab': {
                    padding: '10px 12px 8px 12px',
                    border: '1.5px solid #DCD2CC'
                },
                '.Tab:hover': {
                    border: '1.5px solid #FF5271',
                    // boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
                },
                '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
                    border: 'none',
                    backgroundColor: '#fff',
                    boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
                },
                '.Label': {
                    fontWeight: '500'
                }
            }
        },
    };

    useEffect(() => {
        let amount = 0;
        for (const dis of discounts) {
            let i = 0;
            for (const oi of order) {
                const id = i;
                if (oi.size.includes('Heart') || oi.name.includes('Heart')) {
                    amount += oi.price * quantities[i] * .1;
                }
                i++;
            }
        }
        setDiscount(amount);
        console.log('subTotal: ' + sub + ', discount:' + amount);
        setSubtotal(sub - amount);
    }, [order, quantities, discounts]);

    useEffect(() => {
        if (step === '-payment') {
            const timer = setTimeout(() => {
                setStep('payment');
            }, 400);
            return () => clearTimeout(timer);
        }
    }, [step]);

    useEffect(() => {
        if (tipOption < 0) {
            if (customPercent.length > 0) return;
            setTip(undefined);
        }
        else {
            const tipString = tipOptions[tipOption].name ? tipOptions[tipOption].name : tipOptions[tipOption];
            setTip(USDollar.format(subtotal * parseInt(tipString) / 100));
            setCustomPercentSelected(false);
            setCustomTipSelected(false);
        }
    }, [tipOption]);

    useEffect(() => {
        setDiscountCode('');
        if (discountMessage !== '') {
            const timer = setTimeout(() => {
                setDiscountMessage('');
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [discountMessage]);

    useEffect(() => {
        if (tip !== undefined && tip.length > 0) {
            console.log('tip: ' + tip);
            const numericTip = tip.replaceAll(/[^.0-9]/g, '');
            let total = subtotal + tax + parseFloat(numericTip);
            console.log('total: ' + total);
            setTotal(total.toFixed(2));
        } else {
            let total = subtotal + tax;
            setTotal(total.toFixed(2));
        }
    }, [tax, tip, subtotal]);

    // useEffect(() => {
    //     // console.log('comp: '+(tip?.startsWith('$') ? tip.slice(1) : tip)+' vs '+customPercent.slice(0,-1));
    //     // console.log('comp: '+(''+(parseFloat(tip?.startsWith('$') ? tip.slice(1) : tip) / subtotal)).slice(0,3) +' vs '+ (''+parseFloat(customPercent.slice(0,-1)) / 100).slice(0,3));
    //     setCustomPercentSelected((''+(parseFloat(tip?.startsWith('$') ? tip.slice(1) : tip) / subtotal)).slice(0,3) === (''+parseFloat(customPercent.slice(0,-1)) / 100).slice(0,3));
    //     setTipOption(-1);
    // }, [customPercent]);

    const handleToCart = () => {
        setStep('cart');
        toCart();
    }

    const handleToContact = () => {
        setStep('contact');
        toContact();
    }

    const handleToPayment = () => {
        setStep('-payment');
        toPayment();
    }

    const handleTipFieldChange = e => {
        setTipOption(-1);
        let value = e;
        value = value.replaceAll(/[^.0-9]/g, '');
        //remove excess decimals and decimal values
        if (value.match(/\..*\./g)) {
            value = value.replace(/\./g, function (a, n, str) { return str.indexOf(a) === n ? a : '' });
        }
        value = value.match(/\d*((?:\.)\d{0,2})?/g)[0];
        console.log(value);
        setTip(value);
    };

    const handleTipFieldBlur = e => {
        let value = e.currentTarget.value;
        value = value.replaceAll(/[^.0-9]/g, '');
        if (value.length > 0) value = USDollar.format(value);
        setTip(value);
        setTipOption(-1);
        setCustomTipSelected(true);
        setCustomPercentSelected(false);
    }

    const handleCustomPercentChange = e => {
        let value = e;
        value = value.replace(/[^.0-9]/g, '');
        value = value.slice(0, 5);
        console.log('value: ' + value);
        value = value.match(/\d*((?:\.)\d*)?/g)[0];
        setCustomPercent(value);
    }

    const handleCustomPercentBlur = e => {
        let value = e.currentTarget.value;
        value = value.replaceAll(/[^.0-9]/g, '');
        if (value === '.') value = '0';
        if (value.length > 0) {
            const parsedValue = parseFloat(value) / 100
            value = Number(parsedValue).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 });
            setTip(USDollar.format(subtotal * parsedValue));
        }
        else value = '';
        setCustomPercent(value);

        if (value.length > 0) {
            setCustomPercentSelected(true);
            setCustomTipSelected(false);
            setTipOption(-1);
        }
    }

    const handleDiscountFieldChange = e => {
        let value = e;
        setDiscountCode(value);
    };

    const handleTryDiscountCode = e => {
        if (discountCode.length === 0) return;
        const code = discountCode.toUpperCase();
        // if (code === 'VALEN10<3') {
        //     /* apply discount */
        //     if (discounts.includes(code)) {
        //         setDiscountMessage('You already added ' + code + '!');
        //     } else {
        //         discounts.push(code);
        //         let amount = 0;
        //         for (const dis of discounts) {
        //             let i = 0;
        //             for (const oi of order) {
        //                 const id = i;
        //                 if (oi.size.includes('Heart') || oi.name.includes('Heart')) {
        //                     amount += oi.price * quantities[i] * .1;
        //                 }
        //                 i++;
        //             }
        //         }
        //         setDiscount(amount);
        //         setSubtotal(sub - amount);
        //         setDiscounts(discounts);
        //         setDiscountMessage('*' + code + ' applied!');
        //     }
        // } else {
        //     setDiscountMessage(code + ' is not a valid discount code');
        // }
        setDiscountMessage(code + ' is not a valid discount code');
        setDiscountCode('');
    }

    const handleClickContinueToPayment = e => {
        let missingItems = [];
        if (firstName.length < 1) {
            console.log(firstName);
            missingItems.push('first name');
        }
        if (lastName.length < 1) {
            missingItems.push('last name');
        }
        if (email.length < 7) {
            missingItems.push('email');
        }
        if (phone.length < 10) {
            missingItems.push('phone number');
        }
        console.log(missingItems);
        if (missingItems.length > 0) {
            let errorString = 'Please enter your ';
            if (missingItems.length === 1) {
                setConfirmError(errorString + missingItems[0] + '.');
                return;
            }
            if (missingItems.length === 2) {
                setConfirmError(errorString + missingItems[0] + ' and ' + missingItems[1] + '.');
                return;
            }
            for (var i = 0; i < missingItems.length - 1; i++) {
                errorString += missingItems[i] + ', ';
            }
            errorString += 'and ' + missingItems.slice(-1) + '.';
            setConfirmError(errorString);
        } else if (!agreement) {
            setConfirmError('You must agree to the terms and conditions to continue to payment.')
        } else {
            let newOrderCookie = { ...cookies.get('order') }
            let contact = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone
            }
            newOrderCookie.contactInfo = contact;
            cookies.set('order', newOrderCookie);
            setConfirmError('');
            handleToPayment();
        }
    }

    if (order.length < 1) {
        itemFrames.push(<div className='frame stretch center'>
            <p className='font-28 bold'>There are no items in your cart. Go to the menu to add sweets!</p>
        </div>);
    } else {
        let i = 0;
        for (const item of order) {
            const id = i;
            console.log('item: ', item.id);
            itemFrames.push(
                <OrderItem
                    item={item.item}
                    size={item.states.size}
                    flavor={item.states.flavor}
                    toppings={item.states.toppings}
                    frosting={item.states.frosting}
                    inscription={item.receipt.inscription}
                    inscriptionStyle={item.receipt.inscription_style}
                    colors={item.receipt.colors}
                    notes={item.receipt.notes}
                    price={item.receipt.price}
                    handleClickRemove={() => { setConfirmDelete(id); }}
                    type='horiz' />
            );
            miniItemFrames.push(
                <OrderItem
                    item={item.item}
                    size={item.states.size}
                    flavor={item.states.flavor}
                    toppings={item.states.toppings}
                    frosting={item.states.frosting}
                    inscription={item.receipt.inscription}
                    inscriptionStyle={item.receipt.inscription_style}
                    colors={item.receipt.colors}
                    notes={item.receipt.notes}
                    price={item.receipt.price}
                    type='horiz' />
            );
            i++;
        }
    }

    return (
        <Element name='/'>
            <div className='frame column align-start stretch flex-100 true-white' style={{ padding: '16rem 0' }}>
                <div className='frame checkout column align-start stretch' style={{ gap: '12rem', padding: '0' }}>
                    <StepSlider maxWidth='100vw' step={stepID}>
                        {[
                            <div className='frame column align-start flex-100' style={{ gap: '12rem', marginTop: '60rem' }}>
                                {confirmError.length > 0 && <p className='font-14 primary align-end'>{confirmError}</p>}
                                <CheckoutHeader>Cart <span className='primary'>({order.length})</span></CheckoutHeader>
                                <div className='row-divider-1'></div>
                                <div className='frame column align-items-start stretch' style={{ gap: '16rem', padding: '0 16rem' }}>
                                    {itemFrames}
                                </div>
                                <div className='row-divider-1'></div>
                                <div className='frame column stretch' style={{ gap: '8rem', padding: '0rem 12rem' }}>
                                    <div className='frame stretch align-end' style={{ gap: '16rem' }}>
                                        <Input title={<p className='font-18 semi-bold'>Discount code</p>}
                                            state={discountCode} setState={setDiscountCode} inputStyle={StandardInput()} className='flex-100'
                                            onEnter={handleTryDiscountCode} />
                                        <div className='frame primary center hover-pointer flex-010' style={{ padding: '12rem 24rem', borderRadius: '10rem', border: '2.5rem solid var(--primary)' }}
                                            onClick={handleTryDiscountCode}>
                                            <p className='font-16 bold'>Apply</p>
                                        </div>
                                    </div>
                                    {discountMessage.length > 0 &&
                                        <div className='frame'>
                                            <p className={'font-18 ' + (discountMessage.startsWith('*') ? 'green' : 'primary')}>{discountMessage.startsWith('*') ? discountMessage.slice(1, 0) : discountMessage}</p>
                                        </div>}
                                </div>
                                <div className='row-divider-1'></div>
                                <div className='frame column stretch' style={{ gap: '8rem', padding: '0rem 12rem' }}>
                                    <Input title={<p className='font-18 semi-bold' style={{ gap: '8rem' }}>Add custom tip</p>} placeholder='$0.00'
                                        state={tip} setState={handleTipFieldChange} onBlur={handleTipFieldBlur}
                                        inputStyle={StandardInput()} className='flex-100' selected={customTipSelected} />
                                    <div className='frame stretch align-end' style={{ gap: '8rem' }}>
                                        <Selector
                                            options={tipOptions}
                                            state={tipOption} setState={setTipOption}
                                            optionStyles={ShortOption()} noStretch selectorClass='align-self-end' />
                                        <Input placeholder='20%'
                                            state={customPercent} setState={handleCustomPercentChange} onBlur={handleCustomPercentBlur}
                                            inputStyle={StandardInput()} noStretch className='align-self-end' selected={customPercentSelected} />
                                        {/* <CheckoutFormField className='checkout-tip-field' value={tip} placeholder='$0.00'
                                    onChange={handleTipFieldChange} onBlur={handleTipFieldBlur}></CheckoutFormField> */}
                                    </div>
                                    <div className='frame stretch' style={{ gap: '8rem' }}>
                                        <img style={{ width: '16rem', height: '14rem' }} src={FullHeart}></img>
                                        <p className='font-12 primary'>Every bit helps our business. Thank you for your patronage!</p>
                                    </div>
                                </div>
                                <div className='row-divider-1'></div>
                                <div className='frame column stretch' style={{ gap: '16rem', padding: '0rem 12rem' }}>
                                    {discount > 0 && <div className='frame column stretch' style={{ gap: '8rem' }}>
                                        <div className='frame stretch font-24'>
                                            <p className='font-18 bold frame flex-100'>Discount</p>
                                            <p className='font-18 primary'>-{USDollar.format(discount)}</p>
                                        </div>
                                        {discounts.map((discount) => {
                                            return <div className='frame align-start' style={{ gap: '8rem', padding: '8rem 16rem', borderRadius: '10rem' }}>
                                                <img style={{ width: '20rem', height: '20rem', padding: '4rem 0rem 0rem 4rem' }} src={RedDiscountTag}></img>
                                                <p className='primary font-24'>{discount}</p>
                                            </div>
                                        })}
                                    </div>}
                                    <div className='frame row stretch'>
                                        <p className='font-18 bold frame flex-100'>Subtotal</p>
                                        <p className='font-18'>{USDollar.format(subtotal)}</p>
                                    </div>
                                    {taxable && <div className='frame row stretch'>
                                        <p className='font-18 bold frame flex-100'>Tax</p>
                                        <p className={'font-18' + (step === 'payment' ? '' : ' lighter-grey')}>{step === 'payment' ? USDollar.format(tax) : 'Calulated at next step'}</p>
                                    </div>}
                                    <div className='frame row stretch'>
                                        <p className='font-18 bold frame flex-100'>Tip</p>
                                        <p className='font-18'>{tip ? (tip.startsWith('$') ? tip : USDollar.format(tip)) : '$0'}</p>
                                    </div>
                                </div>
                                <div className='row-divider-1'></div>
                                <div className='frame row stretch' style={{ padding: '0rem 16rem' }}>
                                    <p className='font-24 bold frame flex-100'>Total</p>
                                    <p className={(!taxable || step === 'payment' ? 'bold' : '') + ' primary font-24'}>{!taxable || step === 'payment' ? '$' + total : 'Calulated at next step'}</p>
                                </div>
                                <div className='row-divider-1'></div>
                                <div className='frame stretch column' style={{ gap: '12rem', padding: '12rem' }}>
                                    <div className='frame stretch column' style={{ gap: '12rem' }}>
                                        {order.length > 0 && <div className='frame primary center stretch hover-pointer flex-100' style={{ gap: '12rem', padding: '12rem 24rem', borderRadius: '10rem' }}
                                            onClick={() => {
                                                let newOrderCookie = { ...cookies.get('order') }
                                                newOrderCookie.items.map((orderItem, i) => orderItem.quantity = quantities[i]);
                                                cookies.set('order', newOrderCookie);
                                                let schedule = cookies.get('schedule');
                                                if (schedule && schedule.location) {
                                                    setConfirmError('');
                                                    handleToContact();
                                                } else {
                                                    setConfirmError('Please confirm your pickup date and time!');
                                                    scroller.scrollTo('/', {
                                                        smooth: true,
                                                        duration: 1000
                                                    });
                                                }
                                            }}>
                                            <p className='font-24 bold'>Proceed to Checkout</p>
                                        </div>}
                                        <div className='frame primary inverted center stretch hover-pointer flex-100' style={{ gap: '12rem', padding: '12rem 24rem', borderRadius: '10rem' }}
                                            onClick={() => {
                                                let newOrderCookie = { ...cookies.get('order') }
                                                newOrderCookie.items.map((orderItem, i) => orderItem.quantity = quantities[i]);
                                                cookies.set('order', newOrderCookie);
                                                toMenu();
                                            }}>
                                            <p className='font-24 bold'>Continue Shopping</p>
                                        </div>
                                    </div>
                                    {confirmError.length > 0 && <p className='font-18 primary align-end'>{confirmError}</p>}
                                </div>
                            </div>,
                            <div className='frame column align-start flex-100' style={{ gap: '12rem' }}>
                                <CheckoutHeader>Checkout</CheckoutHeader>
                                <div className='row-divider-1' />
                                <div className='frame column stretch' style={{ gap: '12rem', padding: '0 12rem' }}>
                                    <p className='font-24 bold'>Contact Information</p>
                                    <Input title={<p className='font-18 semi-bold'>First name<span className='primary'>*</span></p>}
                                        state={firstName} setState={setFirstName} inputStyle={StandardInput()} />
                                    <Input title={<p className='font-18 semi-bold'>Last name<span className='primary'>*</span></p>}
                                        state={lastName} setState={setLastName} inputStyle={StandardInput()} />
                                    <Input title={<p className='font-18 semi-bold'>Email<span className='primary'>*</span></p>}
                                        state={email} setState={setEmail} inputStyle={StandardInput()} />
                                    <Input title={<p className='font-18 semi-bold'>Phone</p>} type='tel'
                                        state={phone} setState={setPhone} inputStyle={StandardInput()} />
                                    <Checkbox state={agreement} setState={setAgreement}
                                        prompt="I agree to the terms and conditions." onClick={() => { setAgreement(!agreement) }}></Checkbox>
                                </div>
                                <div className='row-divider-1' />
                                <InfoDrawer title={<p className='font-18 semi-bold'>Terms & Conditions</p>}>
                                    <p className='stretch font-16'>
                                        <span className='bold'>All orders must be placed at least 5 days in advance for pickup only. If you need it before this time, please contact us at <span className='primary'>chefskascravings@gmail.com</span>.</span>
                                        <br /><br />
                                        No refunds for orders orders canceled 24 hours before pickup.
                                        <br /><br />
                                        50% refund for orders canceled more than 24 hours before pickup.
                                        <br /><br />
                                        Orders can be rescheduled 48 hours before pickup time.
                                        <br /><br />
                                        Cake artist can make any modification that she considers necessary on the decoration.
                                        <br /><br />
                                        Seasonal ingredients are subject to change, and we reserve the right to make reasonable substitutions while ensuring the overall quality and flavor profile of our products.
                                        <br /><br />
                                        For darker colors, bold colors are not available for whipped cream.
                                        <br /><br />
                                        Customers with allergies or dietary restrictions should review our ingredient lists and inform us of any special requirements before placing an order. We will do our best to accommodate your needs. We do not offer gluten-free at the moment.
                                        <br /><br />
                                        Certain cakes may require items for decoration and stability. Natural flowers and any ornamental items on cakes are for decoration only and should not be consumed.
                                        <br /><br />
                                        Portions estimated per cake will depend on the size of the slices when cutting the cake.
                                        <br /><br />
                                        Chefska’s Cravings assumes no liability for damages or accidents occurring after pickup. Additional charges may be incurred upon return and modifications.
                                        <br /><br />
                                        <span className='bold'>By placing the order you are accepting all terms and conditions.</span>
                                    </p>
                                </InfoDrawer>
                                <div className='row-divider-1' />
                                <div className='frame column stretch' style={{ gap: '12rem', padding: '0 12rem' }}>
                                    {confirmError.length > 0 && <p className='font-12 primary'>{confirmError}</p>}
                                    <div className='frame primary center stretch hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '12rem' }}
                                        onClick={handleClickContinueToPayment}>
                                        <p className='font-24 bold'>Continue to Payment</p>
                                    </div>
                                    <div className='frame primary inverted center stretch hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '12rem' }}
                                        onClick={() => { handleToCart() }}>
                                        <p className='font-24 bold'>Go Back to Cart</p>
                                    </div>
                                </div>
                            </div>,
                            <div id='checkout-page' className='checkout-section2 column align-items-start stretch flex-100' style={{ gap: '32rem', margin: '2px' }}>
                                <p className='bold font-40'>Checkout</p>
                                <div className='row-divider-1' style={{ marginTop: '16rem', marginBottom: '16rem' }}></div>
                                {clientSecret && clientSecret.includes('_secret_') && <Elements stripe={stripePromise} options={checkoutOptions}>
                                    <CheckoutFinalStep contactInfo={{ firstName: firstName, lastName: lastName, email: email, phone: phone }}
                                        order={order} clientSecret={clientSecret} />
                                </Elements>}
                                <div className='frame primary inverted center stretch hover-pointer' style={{ padding: '18rem 36rem', borderRadius: '10rem', marginTop: '16rem' }}
                                    onClick={() => { handleToContact() }}>
                                    <p className='font-28 bold'>Go Back to Contact Info</p>
                                </div>
                            </div>
                        ]}
                    </StepSlider>
                </div>
                {confirmDelete >= 0 &&
                    <Popup handleGoBack={() => setConfirmDelete(-1)}>
                        <OrderItem
                            item={order[confirmDelete].item}
                            size={order[confirmDelete].states.size}
                            flavor={order[confirmDelete].states.flavor}
                            toppings={order[confirmDelete].states.toppings}
                            frosting={order[confirmDelete].states.frosting}
                            inscription={order[confirmDelete].receipt.inscription}
                            inscriptionStyle={order[confirmDelete].receipt.inscription_style}
                            colors={order[confirmDelete].receipt.colors}
                            notes={order[confirmDelete].receipt.notes}
                            price={order[confirmDelete].receipt.price}
                            type='vert'
                        ></OrderItem>
                        <div className='frame center' style={{ gap: '16rem' }}>
                            <div className='frame primary inverted hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                                onClick={() => setConfirmDelete(-1)}>
                                <p className='bold font-24'>Cancel Delete</p>
                            </div>
                            <div className='frame primary hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                                onClick={() => {
                                    handleRemoveFromCart(order[confirmDelete].id);
                                    setConfirmDelete(-1);
                                }}>
                                <p className='bold font-24'>Confirm Delete</p>
                            </div>
                        </div>
                    </Popup>
                }
            </div>
        </Element>
    );
}

export default Checkout;