import React from 'react';

import CheckBox from '../../assets/CheckBox.svg';
import CheckedBox from '../../assets/CheckedBox.svg';

export default function Checkbox(props) {
    const {
        state, setState,
        onClick,
        className,
        prompt
    } = props;
    return (<div className={className + ' frame hover-pointer'} style={{ gap: '12rem' }} onClick={() => {
        onClick();
        setState(!state);
    }}>
        <img src={state ? CheckedBox : CheckBox} style={{ width: '20rem', height: '20rem' }}></img>
        <p className='font-16 center'>{prompt}</p>
    </div>);
}