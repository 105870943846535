import React from 'react';
import { Link } from 'react-router-dom';

import Popup from './Popup';

class Redirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false
        };
    }

    handleClosePopup = () => {
        this.setState({
            popup: false
        });
    }

    render() {
        if (this.props.external) {
            return <>
                <div
                    className={'redirect hover-pointer ' + this.props.className} id={this.props.id}
                    onClick={() => {
                        this.setState({
                            popup: true
                        });
                        // if (this.props.external) window.location.replace(this.props.to);
                    }}
                    target={this.props.external ? '_blank' : ''}
                    rel={this.props.external ? 'noopener noreferrer' : ''}>
                    {this.props.children}
                </div>
                {this.state.popup && <Popup handleGoBack={this.handleClosePopup}>
                    <div className='frame column center' style={{ gap: '24rem' }}>
                        <p className='font-24'>You're about to leave Chefska's Cravings. Are you sure?</p>
                        <div className='frame column center' style={{ gap: '12rem' }}>
                            <a className='frame primary center hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem', maxWidth: '100%' }}
                                href={this.props.to}>
                                <p className='font-20 ellipsis wrap'>Continue to <span className='underlined'>{this.props.to}</span></p>
                            </a>
                            <p className='primary underlined font-20 hover-pointer' onClick={this.handleClosePopup}>Go back</p>
                        </div>
                    </div>
                </Popup>}
            </>
        }
        return <Link to={this.props.external ? { pathname: this.props.to } : this.props.to}
            className={'redirect hover-pointer ' + this.props.className} id={this.props.id}
            onClick={() => {
                // this.forceUpdate();
                // if (this.props.external) window.location.replace(this.props.to);
            }}
            target={this.props.external ? '_blank' : ''}
            rel={this.props.external ? 'noopener noreferrer' : ''}>
            {this.props.children}
        </Link>
    }
}

export default Redirect;