export default function StandardOption() {
    /**
     * empty
     * hover
     * selected
     * hover-selected deselectable only
     */
    const uniform = {
        padding: '12rem 16rem',
        fontSize: '20rem',
        fontWeight: 'semi-bold',
        borderRadius: '12rem'
    }
    return {
        default: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            fontWeight: uniform.fontWeight,
            borderRadius: uniform.borderRadius,
            color: 'var(--black)',
            border: '2.5rem solid var(--light-brown)',
        },
        hover: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            fontWeight: uniform.fontWeight,
            borderRadius: uniform.borderRadius,
            color: 'var(--primary)',
            border: '2.5rem solid var(--primary)'
        },
        selected: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            fontWeight: uniform.fontWeight,
            borderRadius: uniform.borderRadius,
            color: 'var(--primary)',
            border: '2.5rem solid var(--primary)'
        },
        selectedHover: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            fontWeight: uniform.fontWeight,
            borderRadius: uniform.borderRadius,
            color: 'var(--primary)',
            border: '2.5rem solid var(--primary)'
        }
    }
}