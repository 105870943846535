import React from 'react';

export default function Option(props) {
    const {
        selected,
        handleOnClick,
        deselect,
        styles, addedStyle
    } = props;

    const {
        id,
        name,
        shape,
        color
    } = props.option;

    /**
     * default
     * hover
     * selected
     * hover-selected
     */
    var optionStyle = selected ? styles.selected : styles.default;

    var shapeClass = shape ? shape.toLowerCase() : '';
    switch(name) {
        case '6"':
            shapeClass += '-6';
            break;
        case '8"':
            shapeClass += '-8';
            break;
        case '9"':
            shapeClass += '-9';
            break;
    }

    if(shapeClass.startsWith('heart') || shapeClass.startsWith('circle')) {
        optionStyle = {
            ...optionStyle,
            padding: '0',
            border: '3rem'+optionStyle.border.slice(6),
            borderRadius: (shapeClass.startsWith('circle') ? '50%' : optionStyle.borderRadius)
        }
    }

    optionStyle = {
        ...optionStyle,
        ...addedStyle
    }

    return (
        <div className={'frame center hover-sibling hover-pointer '+shapeClass+(selected ? ' selected' : '')} style={optionStyle}
            onClick={handleOnClick} >
            <p className={'nowrap '+(optionStyle.fontWeight ? optionStyle.fontWeight : '')} style={{ fontSize: optionStyle.fontSize ? optionStyle.fontSize : '20rem'}}>{name + (color ? ' ('+color+')' : '')}</p>
        </div>
    );
}