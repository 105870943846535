import React from 'react';

function translateX(step) {
    return 'translateX(-' + (100 * step) + '%)';
}

export default function StepSlider(props) {
    const {
        step, children,
        padding,
        maxWidth
    } = props;

    const defaultStyle = {
        minWidth: maxWidth, transform: translateX(step),
        transition: 'transform 0.35s ease-in-out, height 10s ease-in-out'
    }

    return (
        <div className='frame step-slider row flex-100' style={{ gap: '0', padding: padding, maxWidth: maxWidth }}>
            {
                children.map((child, i) => {
                    const modifiedStyle = {};
                    if(i !== step) modifiedStyle.height = '0';
                    return <div className='frame flex-100 step-slider-step' style={{
                        ...defaultStyle,
                        ...modifiedStyle
                    }}>
                        {child}
                    </div>;
                })
            }
        </div>
    )
}