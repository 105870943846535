import React, { useState } from 'react';

import MinusSign2 from '../../assets/MinusSign2.svg';
import PlusSign2 from '../../assets/PlusSign2.svg';

export default function InfoDrawer(props) {
    const {
        className,
        title, info,
        children
    } = props;

    const [open, setOpen] = useState(false);

    const description = title || info;

    return (
        <div className={className + ' drawer frame column center stretch ' + (open ? 'open' : 'close')} style={{ gap: '16rem', marginBottom: open ? '0' : '-16rem' }}>
            <div className='frame row align-start stretch hover-pointer' style={{ gap: '16rem', paddingRight: '16rem' }} onClick={() => setOpen(!open)}>
                {description &&
                    <div className='frame row justify-between stretch flex-100'>
                        <p className='font-24 semi-bold'>{title}</p>
                        <p className='font-24 primary semi-bold'>{info}</p>
                    </div>
                }
                <div className='frame center align-self-center' style={{ width: '20rem', height: '20rem' }}>
                    <img style={{ width: '20rem', height: open ? '4rem' : '20rem' }} src={open ? MinusSign2 : PlusSign2}></img>
                </div>
            </div>
            <div className={'drawer-children column stretch ' + (open ? 'open' : 'close')} style={{ gap: '32rem' }}>
                {children}
            </div>
        </div>
    );
}