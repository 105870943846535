import React from 'react';

import Redirect from './Redirect';

function Breadcrumbs(props) {
    const routes = [];
    for(const r in props.routes) {
        routes.push(<p className='font-20 faded-grey'>/</p>);
        routes.push(<Redirect to={props.routes[r].url}>
            <p className={'font-20 underline-hover uppercase'+(r === props.routes.length-1 ? ' primary' : ' faded-grey')}>{props.routes[r].name}</p>
            </Redirect>)
    }
    return (<div className='frame align-start stretch' style={{ gap: '16rem' }}>
        <Redirect to='/'><p className='font-20 underline-hover faded-grey uppercase'>HOME</p></Redirect>
        {routes}
    </div>);
}

export default Breadcrumbs;