import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import SessionContext from '../contexts/SessionContext';
import CartContext from '../contexts/CartContext';

import Redirect from '../components/Redirect';

import Scheduler from './Scheduler';
import { toShortFormat, toShorterFormat } from '../../functions/Datetimes';

import Logo from '../../assets/Logo1.svg';
import GreenDot from '../../assets/GreenDot.png'
import OrderPickupIcon from '../../assets/OrderPickupIcon.svg';
import BannerIcon from '../../assets/BannerIcon.svg';
// import AlwaysYoursSecondAnniIcon from '../../assets/AlwaysYoursSecondAnniIcon.png';

const cookies = new Cookies(null, { path: '/' });

function HeaderItem(props) {
    const { location, link, name } = props;
    const [active, setActive] = useState(location.pathname.startsWith(link));

    useEffect(() => {
        setActive(location.pathname.startsWith(link));
    }, [location]);

    return (
        <Redirect to={link}><p className={'font-20 uppercase hover-primary bold-hover ' + (active ? 'primary bold' : 'black semi-bold')} name={name.toUpperCase()}>{name}</p></Redirect>
    );
}

function Header(props) {
    const { session } = useContext(SessionContext);
    const { cart } = useContext(CartContext);
    console.log('cart: ', cart);
    const location = useLocation();
    const [nextAvailability, setNextAvailability] = useState('');

    useEffect(() => {
        //get next availability
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/scheduler')
            .then((res) => res.json())
            .then((data) => {
                // console.log('DATA: ' + JSON.stringify(data));
                if (new Date(data[0].start_time).getTime() < new Date(data[1].start_time).getTime())
                    setNextAvailability(toShorterFormat(toShortFormat(new Date(data[0].start_time))));
                else
                    setNextAvailability(toShorterFormat(toShortFormat(new Date(data[1].start_time))));
            });
    }, []);

    return (
        <>
            <Routes>
                <Route path='/' element={
                    <div className='frame row center stretch primary' style={{ gap: '10rem', padding: '12rem' }}>
                        <img src={BannerIcon} style={{ width: '20rem', height: '20rem' }}></img>
                        <p className='font-18'>We only accept orders for <span className='bold'>pickup in San Leandro, CA and tentatively Castro Valley, CA</span>. Please check the next available pickup date before ordering. :{')'}</p>
                    </div>
                } />
                <Route path='/menu/*' element={
                    <div className='frame row center stretch primary' style={{ gap: '10rem', padding: '12rem' }}>
                        <img src={BannerIcon} style={{ width: '20rem', height: '20rem' }}></img>
                        <p className='font-18'>We only accept orders for <span className='bold'>pickup in San Leandro, CA and tentatively Castro Valley, CA</span>. Please check the next available pickup date before ordering. :{')'}</p>
                    </div>
                } />
                <Route path='/checkout' element={
                    <div className='frame row center stretch primary' style={{ gap: '10rem', padding: '12rem' }}>
                        <img src={BannerIcon} style={{ width: '20rem', height: '20rem' }}></img>
                        <p className='font-18'>We only accept orders for <span className='bold'>pickup in San Leandro, CA and tentatively Castro Valley, CA</span>. Please check the next available pickup date before ordering. :{')'}</p>
                    </div>
                } />
                <Route path='/checkout/cart' element={
                    <div className='frame row center stretch primary' style={{ gap: '10rem', padding: '12rem' }}>
                        <img src={BannerIcon} style={{ width: '20rem', height: '20rem' }}></img>
                        <p className='font-18'>We only accept orders for <span className='bold'>pickup in San Leandro, CA and tentatively Castro Valley, CA</span>. Please check the next available pickup date before ordering. :{')'}</p>
                    </div>
                } />
            </Routes>
            <div className='frame row center stretch white sticky' style={{ gap: '60rem', padding: '20rem 28rem', height: '60rem', zIndex: '20' }}>
                <Redirect to='/'>
                    <img className='header-logo hover-pointer' src={Logo} style={{ width: '141rem', height: '60rem', marginLeft: '12rem' }}></img>
                </Redirect>
                <div className='frame row align-start flex-100' style={{ gap: '26rem' }}>
                    <HeaderItem location={location} link='/menu' name='menu'></HeaderItem>
                    <HeaderItem location={location} link='/about' name='about'></HeaderItem>
                    <HeaderItem location={location} link='/contact' name='contact'></HeaderItem>
                    {/* <HeaderItem location={location} link='/my-orders' name={session ? 'my orders' : 'login'}></HeaderItem> */}
                </div>
                <div className='frame center align-end' style={{ gap: '12rem' }}>
                    <div className='frame center stretch green' style={{ gap: '10rem', padding: '12rem 20rem', borderRadius: '20rem' }}>
                        <img src={GreenDot} style={{ width: '12rem', height: '10rem' }}></img>
                        <p className='font-20 green semi-bold uppercase'>Next Available Pick Up: {nextAvailability}</p>
                    </div>
                    <Redirect to={(cart.items && cart.items.length > 0) ? '/checkout' : '/menu'}>
                        <div className='frame center stretch primary' style={{ gap: '12rem', padding: '12rem 24rem', borderRadius: '20rem' }}>
                            <p className='font-20 semi-bold uppercase'>Order Pick Up {cart.items && cart.items.length > 0 ? '(' + cart.items.length + ')' : ''}</p>
                            <img src={OrderPickupIcon} style={{ width: '26rem', height: '26rem' }}></img>
                        </div>
                    </Redirect>
                </div>
                <Routes>
                    <Route path='/menu/*' element={<Scheduler />} />
                    <Route path='/checkout' element={<Scheduler />} />
                    <Route path='/checkout/cart' element={<Scheduler />} />
                </Routes>
            </div>
        </>
    );
}

export default Header;