import React from 'react';

import Redirect from './Redirect';

import { photoMap } from '../../functions/PhotoMap';

function MenuItem(props) {
    let parsedURL = props.url.split('/');
    return (
        <Redirect to={'/menu' + props.url}>
            <div className='frame center stretch column' style={{ gap: '8rem', padding: '29rem 0' }}>
                <img src={photoMap.get(props.url)} className={photoMap.get(props.url) ? '' : 'item-image ' + props.flavor} style={{ height: '232rem', width: '260rem' }}></img>
                <div className='frame column center stretch flex-100' style={{gap: '4rem', padding: '0 0 0 30rem'}}>
                    <p className='font-24 stretch bold' style={{width: '250rem'}}>{props.name}</p>
                    <p className='font-24 faded-grey stretch'>{props.price}</p>
                </div>
            </div>
            {/* <Frame className={props.className + ' menu-item center stretch'} height={props.height} gap='9rem'>
                <Frame className='column center' padding='29rem 0rem'>
                    <img src={photoMap.get(props.url)} className={photoMap.get(props.url) ? '' : 'item-image ' + props.flavor} style={{ height: '232rem', width: '260rem' }}></img>
                    <Frame className='item-info column center stretch' gap='14.5rem' padding='0rem 0rem 0rem 30rem'>
                        <Frame className='center stretch' gap='3.5rem'>
                            <Frame className='align-start flex-100' gap='3.5rem'>
                                <Frame className='column align-items-start flex-100' gap='3.5rem'>
                                    <p className='menu-item-name stretch bold'>{props.name}</p>
                                    <p className='menu-item-price stretch'>{props.price}</p>
                                </Frame>
                            </Frame>
                        </Frame>
                    </Frame>
                </Frame>
            </Frame> */}
        </Redirect>
    );
}

export default MenuItem;