import React from 'react';

export default function Popup(props) {
    const { handleGoBack, children } = props;

    return (
        <>
            <div className='confirm-background' onClick={handleGoBack}></div>
            <div className='confirm-container column center' style={{ gap: '24rem', borderRadius: '20rem' }}>
                { children }
            </div>
        </>
    );
}