import React, { useEffect, useState } from 'react';

import Redirect from '../components/Redirect';

import HomeGraphic from '../../assets/HomeGraphic.svg';
import CreateYourOwnIcon from '../../assets/CreateYourOwnIcon.svg';

function Home(props) {
    const [instagram, setInstagram] = useState([]);

    useEffect(() => {
        fetch(`https://graph.instagram.com/me/media?fields=id,media_url&access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN}`, {
            method: "GET",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => {
                // console.log(JSON.stringify(res));
                // console.log(res.data[0].media_url);
                setInstagram(res.data);
            });
    }, []);

    return (
        // <div className='frame column stretch center'>
        <>
            <img src={HomeGraphic} style={{ width: '100vw', background: 'linear-gradient(#FEF4E8, #FF5271, #FEF4E8, #FEF4E8)' }}></img>
            <div className='frame row stretch sticky align-center primary' style={{ gap: '64rem', padding: '32rem 80rem', top: '100rem' }}>
                <div className='frame flex-100' style={{ gap: '64rem' }}>
                    <Redirect to='/menu/chiffon-cakes'><p className='font-24 bold-hover white' name='CAKES'>CAKES</p></Redirect>
                    <Redirect to='/menu/cupcakes'><p className='font-24 bold-hover white' name='CUPCAKES'>CUPCAKES</p></Redirect>
                    <Redirect to='/menu/crinkles'><p className='font-24 bold-hover white' name='CRINKLES'>CRINKLES</p></Redirect>
                    <Redirect to='/menu/tiramisu'><p className='font-24 bold-hover white' name='TIRAMISU'>TIRAMISU</p></Redirect>
                    <Redirect to='/menu/seasonal-sweets'><p className='font-24 bold-hover white' name='SEASONAL SWEETS'>SEASONAL SWEETS</p></Redirect>
                </div>
                <Redirect to='/menu/custom-cakes'>
                    <div className='frame center stretch white' style={{ gap: '12rem', padding: '12rem 24rem', borderRadius: '20rem' }}>
                        <p className='font-24 semi-bold uppercase primary'>Create Your Own</p>
                        <img src={CreateYourOwnIcon} style={{ width: '28rem', height: '28rem' }}></img>
                    </div>
                </Redirect>
            </div>
            <div className='offwhite stretch' style={{ padding: '1vw' }}>
                <Redirect to='https://www.instagram.com/chefskascravings/' className='frame center stretch' external>
                    <div className='frame stretch wrap align-center' style={{ gap: '.5vw' }}>
                        {instagram.map((post) => <img src={post.media_url} style={{ width: '32vw', height: '32vw', borderRadius: '12rem' }}></img>)}
                    </div>
                </Redirect>
            </div>
        </>
        // </div>
    );
}

export default Home;