import React, { useRef } from 'react';

function Input(props) {
    const inputRef = useRef(null);

    const {
        state, setState,
        selected,
        className, inputStyle, noStretch,
        title, info, placeholder, type,
        maxWords,
        onBlur, onEnter,
        error, setError
    } = props;

    const handleChange = e => {
        const value = e.target.value;
        if (maxWords && value.split(' ').length > maxWords) {
            setState(state);
            return;
        }
        setState(value);
    }

    const handleKeyDown = e => {
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                if (onEnter) onEnter();
                else inputRef.current.blur();
                break;
        }
    }

    // console.log('selected: '+selected);
    var currentInputStyle = selected ? inputStyle.selected : inputStyle.default;

    const description = title || info;

    return (
        <div className={'frame column center ' + (noStretch ? '' : 'stretch ') + className} style={{ gap: '12rem' }}>
            {description &&
                <div className='frame row justify-between stretch'>
                    <p className='font-20 semi-bold'>{title}</p>
                    <p className='font-20 primary semi-bold'>{info}</p>
                </div>
            }
            <div className='frame row align-end stretch nowrap' style={currentInputStyle} onClick={() => inputRef.current.focus()}>
                <input className={'frame flex-100 stretch semi-bold font-16'+(selected ? ' primary':'')} style={{ width: '100%' }}
                    value={state}
                    placeholder={placeholder}
                    ref={inputRef}
                    pattern={type === 'tel' ? '\([0-9]{3}\)[0-9]{3}-[0-9]{4}' : '.*'}
                    onChange={handleChange}
                    onBlur={onBlur}
                    onKeyDown={handleKeyDown} />
                {maxWords && <p className='font-16 light-grey'>Max: {maxWords} words</p>}
            </div>
            {error &&
                <p className='font-16 primary'>{error}</p>
            }
        </div>
    );
}

export default Input;