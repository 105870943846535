export default function PillOption() {
    /**
     * empty
     * hover
     * selected
     * hover-selected deselectable only
     */
    const uniform = {
        color: 'var(--black)',
        padding: '8rem 16rem',
        fontSize: '20rem',
        borderRadius: '32rem'
    }
    return {
        default: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            borderRadius: uniform.borderRadius,
            color: uniform.color,
            border: '2rem solid var(--light-grey)',
        },
        hover: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            borderRadius: uniform.borderRadius,
            backgroundColor: '#0000',
            color: 'var(--primary)',
            border: '2rem solid var(--primary)'
        },
        selected: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            borderRadius: uniform.borderRadius,
            color: uniform.color,
            border: '2rem solid var(--primary)'
        },
        selectedHover: {
            padding: uniform.padding,
            fontSize: uniform.fontSize,
            borderRadius: uniform.borderRadius,
            color: 'var(--primary)',
            border: '2rem solid var(--primary)'
        }
    }
}