function getSelectorNames(options, state) {
    if (state === undefined) return '';
    if (typeof (state) === typeof (1)) {
        return options[state].name;
    }
    var selected = [];
    for (const option of state) {
        selected.push(option.name + (option.color ? ' (' + option.color + ')' : ''));
    }
    return selected.join(', ');
}

function getSelectorPrice(options, state) {
    if (typeof (state) === typeof (1)) {
        return options[state].price;
    }
    var price = 0;
    const filteredState = state.filter(
        (o1, index) =>
            state.findIndex((o2) => o1.name === o2.name) === index
    );
    for (const option of filteredState) {
        price += option.price;
    }
    return price;
}

export { getSelectorNames, getSelectorPrice };