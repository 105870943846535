import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import CartContext from '../contexts/CartContext';
import ScheduleContext from '../contexts/ScheduleContext';

const cookies = new Cookies(null, { path: '/' });

export default function PaymentComplete(props) {
    const { handleResetCart } = useContext(CartContext);
    const { handleResetSchedule } = useContext(ScheduleContext);
    const [description, setDescription] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        fetch(`https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN}`, {
            method: "GET",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/json'
            }
        })
            .then(res => res.json())
            .then(res => {
                console.log('res', res);
            });
        const payment_intent = searchParams.get('payment_intent');
        // console.log(searchParams.get('payment_intent'));
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/get-payment-intent?payment_intent=' + payment_intent)
            .then((res) => res.json())
            .then((data) => {
                // console.log('data: '+JSON.stringify(data, null, 4));
                setDescription(data.description);
                handleResetCart()
                handleResetSchedule();
            });
    }, []);

    return (<div className='frame column true-white' style={{ gap: '24rem', padding: '80rem' }}>
        <p className='font-28 bold'>Your order has been successfully placed! Please wait 24-72 hours for a confirmation email.</p>
        <p className='font-28 primary bold'>{description}</p>
    </div>);
}