const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function getDayOfWeek(day) {
    return isNaN(day) ? null :
        days[day];
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function getMonth(month) {
    return isNaN(month) ? null :
        months[month];
}

const shortMonths = new Map();
shortMonths.set('January', 'Jan');
shortMonths.set('February', 'Feb');
shortMonths.set('March', 'Mar');
shortMonths.set('April', 'Apr');
shortMonths.set('May', 'May');
shortMonths.set('June', 'June');
shortMonths.set('July', 'July');
shortMonths.set('August', 'Aug');
shortMonths.set('September', 'Sept');
shortMonths.set('October', 'Oct');
shortMonths.set('November', 'Nov');
shortMonths.set('December', 'Dec');

function toDate(date) {
    const t = date.split(/[- :]/);
    // console.log(date);
    // const jsDate = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
    const jsDate = new Date(date);

    return jsDate;
}

function monthDateToShortDate(month, date) {
    const monthDate = new Date(2023, month -1, date);
    return getDayOfWeek(monthDate.getDay()) + ', ' + shortMonths.get(months[month]) + ' ' + date;
}

function toLongFormat(jsDate) {
    const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    }
    // console.log('jsDate: '+jsDate);

    const longDate = jsDate.toLocaleDateString(undefined, options);

    // console.log(longDate);

    return longDate;
}

function toShortFormat(jsDate) {
    const longDate = toLongFormat(jsDate);
    // console.log(longDate);
    var index = -1;
    var i = 0;
    while (index < 0) {
        index = longDate.indexOf(getMonth(i));
        i++;
    }
    i--;
    // console.log(i);
    return getDayOfWeek(jsDate.getDay()) + ', ' + shortMonths.get(months[i]) + ' ' + jsDate.getDate();
}

function toShorterFormat(shortFormat) {
    let sf = shortFormat.split(' ');
    sf[0] = sf[0].slice(0, 3) + ',';
    return sf.join(' ');
}

//time converters
/* time formats
 * 00:00 AM
 * 00:00 - 00:15 AM
 */
function toReadableTime(hourMinute) {
    const AMPM = (hourMinute.hour < 12) ? 'AM' : 'PM';
    const hour = hourMinute.hour % 12;
    const shortTime = (hour === 0 ? 12 : hour) + ':' + String(hourMinute.minute).padStart(2, '0') + ' ' + AMPM;
    return shortTime;
}

function toShortTime(hourMinute) {
    const AMPM = (hourMinute.hour < 12) ? 'AM' : 'PM';
    const hour = hourMinute.hour % 12;
    const shortTime = String(hour === 0 ? 12 : hour).padStart(2, '0') + ':' + String(hourMinute.minute).padStart(2, '0') + ' ' + AMPM;
    return shortTime;
}

function toLongTime(hourMinute) {
    const newMinute = (hourMinute.minute + 15) % 60;
    const hour = (newMinute > hourMinute.minute ? hourMinute.hour : hourMinute.hour + 1);
    const AMPM = hour < 12 ? 'AM' : 'PM';
    const newHour = hour % 12;
    const longTime = toReadableTime(hourMinute) + ' - ' + (newHour === 0 ? 12 : newHour) + ':' + String(newMinute).padStart(2, '0') + ' ' + AMPM;
    return longTime;
}

export { days, getDayOfWeek, months, getMonth, shortMonths, toDate, toLongFormat, toShortFormat, toShorterFormat, monthDateToShortDate, toReadableTime, toShortTime, toLongTime };