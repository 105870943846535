import React from 'react';

import Redirect from '../components/Redirect';

import Logo from '../../assets/Logo2.svg';
import Instagram from '../../assets/Instagram.svg';
// import Facebook from '../../assets/Facebook.svg';

function Footer(props) {
    return (
        <div className='frame column stretch'>
            <div className='frame row stretch white align-start' style={{ gap: '80rem', padding: '64rem 0rem 64rem 32rem' }}>
                <Redirect to='/verse'><img src={Logo} style={{ top: '0', width: '226rem', height: '120rem' }}></img></Redirect>
                <div className='frame column' style={{ gap: '24rem' }}>
                    <p className='font-18 primary uppercase bold'>The Chef</p>
                    <div className='frame column' style={{ gap: '16rem' }}>
                        <Redirect to='/about'><p className='font-18 uppercase black'>about</p></Redirect>
                        {/* <Redirect to='/about'><p className='font-18 uppercase black'>events</p></Redirect> */}
                    </div>
                </div>
                <div className='frame column' style={{ gap: '24rem' }}>
                    <p className='font-18 primary uppercase bold'>Menu</p>
                    <div className='frame row' style={{ gap: '40rem' }}>
                        <div className='frame column' style={{ gap: '16rem' }}>
                            <Redirect to='/menu/custom-cakes'><p className='font-18 uppercase black'>custom cakes</p></Redirect>
                            <Redirect to='/menu/chiffon-cakes'><p className='font-18 uppercase black'>chiffon cakes</p></Redirect>
                            <Redirect to='/menu/cupcakes'><p className='font-18 uppercase black'>cupcakes</p></Redirect>
                        </div>
                        <div className='frame column' style={{ gap: '16rem' }}>
                            <Redirect to='/menu/crinkles'><p className='font-18 uppercase black'>crinkles</p></Redirect>
                            <Redirect to='/menu/tiramisu'><p className='font-18 uppercase black'>tiramisu</p></Redirect>
                            <Redirect to='/menu/seasonal-sweets'><p className='font-18 uppercase black'>seasonal sweets</p></Redirect>
                        </div>
                    </div>
                </div>
                <div className='frame column' style={{ gap: '24rem' }}>
                    <p className='font-18 primary uppercase bold'>Contact</p>
                    <div className='frame column' style={{ gap: '16rem' }}>
                        <a href='mailto:chefskascravings@gmail.com?subject=Customer Support'><p className='font-18 uppercase black'>CHEFSKASCRAVINGS@GMAIL.COM</p></a>
                        <a href="mailto: help@chefskascravings.com?subject=Chefska's Cravings Customer Support"><p className='font-18 uppercase black'>CUSTOMER SUPPORT</p></a>
                        <div className='frame row' style={{ gap: '16rem', padding: '0' }}>
                            <Redirect to='https://www.instagram.com/chefskascravings/' external><img src={Instagram} style={{ width: '24rem', height: '24rem' }}></img></Redirect>
                        </div>
                    </div>
                </div>
            </div>
            <div className='frame center primary' style={{ padding: '16rem' }}>
                <p className='font-20 uppercase'>Designed by Juliana Viado & developed by <a className='primary' href='https://www.instagram.com/deevonrey/'>Devon Rey Dy-Liacco</a></p>
            </div>
        </div>
    );
}

export default Footer;