import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import './css/App.css';
import './css/Frames.css';
import './css/Fonts.css';
import './css/Stripe.css';

import SessionContext from './views/contexts/SessionContext';
import CartContext from './views/contexts/CartContext';
import ScheduleContext from './views/contexts/ScheduleContext';

import useDeviceType from './views/hooks/useDeviceType';

import Desktop from './views/layouts/Desktop';
import Mobile from './views/layouts/Mobile';

const cookies = new Cookies(null, { path: '/' });

const get_text_file = async (filepath) => {
  // prefix public dir files with `process.env.PUBLIC_URL`
  // see https://create-react-app.dev/docs/using-the-public-folder/
  const pathname = process.env.REACT_APP_BASE_URL + filepath;
  console.log('pathname: ' + pathname);

  const res = await fetch(pathname);

  // check for errors
  if (!res.ok) {
    throw res;
  }

  return res.text();
};

export function TextFile(props) {
  const [text, setText] = useState(''); // init with an empty string

  useEffect(() => {
    get_text_file(`${props.fileName}.txt`).then((res) => { console.log('res: ' + res); setText(res); }).catch(console.error);
  }, []);

  return (
    <>
      <p>{text}</p>
    </>
  );
}

/**
 * available to all pages
 * sessions
 * cart
 * schedule
 * availabilities
 */
function App(props) {
  const { isMobile } = useDeviceType();
  if (isMobile) {
    document.documentElement.style.setProperty('--font-size', '.25vw');
  } else {
    document.documentElement.style.setProperty('--font-size', '.058vw');
  }

  const sessionCookie = cookies.get('session');

  const [session, setSession] = useState(sessionCookie ? sessionCookie : null);

  const handleLogin = (email, password) => {
    const loginBody = JSON.stringify({
      email: email,
      password: password
    })
    fetch(process.env.REACT_APP_API_URL + '/login', {
      method: "POST",
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        "Content-Type": 'application/json'
      },
      body: loginBody
    })
      .then(res => res.json())
      .then((res) => {
        cookies.set('session', res);
        setSession(res);
      });
  }

  const handleLogout = () => {
    cookies.remove('session');
    setSession(null);
  }

  const cartCookie = cookies.get('order');

  const [cart, setCart] = useState(cartCookie?.items ? cartCookie : {
    nextID: 0,
    items: []
  });

  useEffect(() => {
    cookies.set('order', cart);
  }, [cart]);

  const handleAddToCart = (item) => {
    console.log('item: ' + JSON.stringify(cart, null, 4));

    const newOrderCookie = { ...cart };
    item.id = cart.nextID;
    newOrderCookie.items.push(item);
    newOrderCookie.nextID = cart.nextID + 1;

    setCart(newOrderCookie);
    console.log('orderCookie: ', newOrderCookie, null, 4);
  }

  const handleRemoveFromCart = (itemID) => {
    var cartItems = [...cart.items];
    cartItems = cartItems
      .filter((cartItem) => { return cartItem.id !== itemID })
      .map((cartItem, i) => {
        return {
          ...cartItem,
          id: i
        }
      });

    var newOrderCookie = {
      nextID: cartItems.length,
      items: cartItems
    }

    setCart(newOrderCookie);
  }

  const handleResetCart = () => {
    const newOrderCookie = {
      nextID: 0,
      items: []
    };
    setCart(newOrderCookie);
  }

  const scheduleCookie = cookies.get('schedule');

  const [schedule, setSchedule] = useState(scheduleCookie ? scheduleCookie : null);

  useEffect(() => {
    cookies.set('schedule', schedule);
  }, [schedule]);

  const [location, setLocation] = useState(scheduleCookie ? scheduleCookie.locID : 0);
  const [date, setDate] = useState(scheduleCookie ? scheduleCookie.dateID : 0);
  const [time, setTime] = useState(scheduleCookie ? scheduleCookie.timeID : 0);

  const handleResetSchedule = () => {
    setSchedule(null);
    setLocation(0);
    setDate(0);
    setTime(0);
  }

  console.log('isMobile: ' + isMobile);

  return (
    <div className='App frame column' style={{ minHeight: '100vh' }}>
      <SessionContext.Provider value={{ session, handleLogin, handleLogout }}>
        <CartContext.Provider value={{ cart, handleAddToCart, handleRemoveFromCart, handleResetCart }}>
          <ScheduleContext.Provider value={{ schedule, setSchedule, location, setLocation, date, setDate, time, setTime, handleResetSchedule }}>
            {isMobile ?
              <Mobile /> :
              <Desktop />
            }
          </ScheduleContext.Provider>
        </CartContext.Provider>
      </SessionContext.Provider>
    </div>
  );
}

export default App;
