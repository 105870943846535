import BananaCupcake from '../photos/BananaCupcake.png';
import ChocolateChiffon from '../photos/ChocolateChiffon.png';
import ChocolateCrinkles from '../photos/ChocolateCrinkles.png';
import ChocolateCupcake from '../photos/ChocolateCupcake.png';
import ChocolateDeluxeChiffon from '../photos/ChocolateDeluxeChiffon.png';
import ChocolateHeart from '../photos/ChocolateHeart.png';
import CustomHeart from '../photos/CustomHeart.png';
import CustomStandard from '../photos/CustomStandard.png';
import DulceDeLeche from '../photos/DulceDeLeche.png';
import MangoChiffonCake from '../photos/MangoChiffonCake.png';
import MochaChiffon from '../photos/MochaChiffon.png';
import PandanCrinkles from '../photos/PandanCrinkles.png';
import RedVelvetCupcake from '../photos/RedVelvetCupcake.png';
import StrawberryCrinkles from '../photos/StrawberryCrinkles.png';
import StrawberryCupcake from '../photos/StrawberryCupcake.png';
import StrawberryMatchaTiramisu from '../photos/StrawberryMatchaTiramisu.png';
import Tiramisu from '../photos/Tiramisu.png';
import UbeChiffon from '../photos/UbeChiffon.png';
import UbeCrinkles from '../photos/UbeCrinkles.png';
import UbeCupcake from '../photos/UbeCupcake.png';
import UbeHeart from '../photos/UbeHeart.png';
import VanillaChiffon from '../photos/VanillaChiffon.png';
import VanillaChiffonDeluxe from '../photos/VanillaChiffonDeluxe.png';
import VanillaHeart from '../photos/VanillaHeart.png';
import BananaPudding from '../photos/BananaPudding.png';
import OreoChiffon from '../photos/OreoChiffon.png';
import CinnamonBun from '../photos/CinnamonBun.png';
import HeartCrinkles from '../photos/HeartCrinkles.png';
import MangoChiffon from '../photos/MangoChiffon.png';

import BananaCupcake2 from '../photos/BananaCupcake2.png';
import ChocolateChiffon2 from '../photos/ChocolateChiffon2.png';
import ChocolateCrinkles2 from '../photos/ChocolateCrinkles2.png';
import ChocolateCupcake2 from '../photos/ChocolateCupcake2.png';
import ChocolateDeluxeChiffon2 from '../photos/ChocolateDeluxeChiffon2.png';
import ChocolateHeart2 from '../photos/ChocolateHeart2.png';
import CustomHeart2 from '../photos/CustomHeart2.png';
import CustomStandard2 from '../photos/CustomStandard2.png';
import DulceDeLeche2 from '../photos/DulceDeLeche2.png';
import MangoChiffonCake2 from '../photos/MangoChiffon2.png';
import MochaChiffon2 from '../photos/MochaChiffon2.png';
import PandanCrinkles2 from '../photos/PandanCrinkles2.png';
import RedVelvetCupcake2 from '../photos/RedVelvetCupcake2.png';
import StrawberryCrinkles2 from '../photos/StrawberryCrinkles2.png';
import StrawberryCupcake2 from '../photos/StrawberryCupcake2.png';
import StrawberryMatchaTiramisu2 from '../photos/StrawberryMatchaTiramisu2.png';
import Tiramisu2 from '../photos/Tiramisu2.png';
import UbeChiffon2 from '../photos/UbeChiffon2.png';
import UbeCrinkles2 from '../photos/UbeCrinkles2.png';
import UbeCupcake2 from '../photos/UbeCupcake2.png';
import UbeHeart2 from '../photos/UbeHeart2.png';
import VanillaChiffon2 from '../photos/VanillaChiffon2.png';
import VanillaChiffonDeluxe2 from '../photos/VanillaChiffonDeluxe2.png';
import VanillaHeart2 from '../photos/VanillaHeart2.png';
import BananaPudding2 from '../photos/BananaPudding2.png';
import OreoChiffon2 from '../photos/OreoChiffon2.png';
import CinnamonBuns from '../photos/CinnamonBuns.png';
import MangoChiffonSlice from '../photos/MangoChiffonSlice.png';

import BananaCupcake3 from '../photos/BananaCupcake3.png';
import ChocolateChiffon3 from '../photos/ChocolateChiffon3.png';
import ChocolateCrinkles3 from '../photos/ChocolateCrinkles3.png';
import ChocolateCupcake3 from '../photos/ChocolateCupcake3.png';
import ChocolateDeluxeChiffon3 from '../photos/ChocolateDeluxeChiffon3.png';
import ChocolateHeart3 from '../photos/ChocolateHeart3.png';
import CustomHeart3 from '../photos/CustomHeart3.png';
import CustomStandard3 from '../photos/CustomStandard3.png';
import DulceDeLeche3 from '../photos/DulceDeLeche3.png';
import MangoChiffonCake3 from '../photos/MangoChiffon3.png';
import MochaChiffon3 from '../photos/MochaChiffon3.png';
import PandanCrinkles3 from '../photos/PandanCrinkles3.png';
import RedVelvetCupcake3 from '../photos/RedVelvetCupcake3.png';
import StrawberryCrinkles3 from '../photos/StrawberryCrinkles3.png';
import StrawberryCupcake3 from '../photos/StrawberryCupcake3.png';
import StrawberryMatchaTiramisu3 from '../photos/StrawberryMatchaTiramisu3.png';
import Tiramisu3 from '../photos/Tiramisu3.png';
import UbeChiffon3 from '../photos/UbeChiffon3.png';
import UbeCrinkles3 from '../photos/UbeCrinkles3.png';
import UbeCupcake3 from '../photos/UbeCupcake3.png';
import UbeHeart3 from '../photos/UbeHeart3.png';
import VanillaChiffon3 from '../photos/VanillaChiffon3.png';
import VanillaChiffonDeluxe3 from '../photos/VanillaChiffonDeluxe3.png';
import VanillaHeart3 from '../photos/VanillaHeart3.png';
import BananaPudding3 from '../photos/BananaPudding3.png';
import OreoChiffon3 from '../photos/OreoChiffon3.png';

const photoMap = new Map();
photoMap.set('/custom-cakes/heart', CustomHeart);
photoMap.set('/custom-cakes/standard', MangoChiffonCake);
photoMap.set('/chiffon-cakes/chocolate', ChocolateChiffon);
photoMap.set('/chiffon-cakes/chocolate-deluxe', ChocolateDeluxeChiffon);
photoMap.set('/chiffon-cakes/vanilla', VanillaChiffon);
photoMap.set('/chiffon-cakes/vanilla-berries', VanillaChiffonDeluxe);
photoMap.set('/chiffon-cakes/ube', UbeChiffon);
photoMap.set('/chiffon-cakes/mocha', MochaChiffon);
// photoMap.set('/chiffon-cakes/chocolate-oreo', 'empty');
photoMap.set('/cupcakes/banana', BananaCupcake);
photoMap.set('/cupcakes/ube', UbeCupcake);
photoMap.set('/cupcakes/strawberry-cheesecake', StrawberryCupcake);
photoMap.set('/cupcakes/chocolate-oreo', ChocolateCupcake);
// photoMap.set('/cupcakes/mocha', 'empty');
photoMap.set('/cupcakes/red-velvet', RedVelvetCupcake);
photoMap.set('/crinkles/chocolate', ChocolateCrinkles);
photoMap.set('/crinkles/pandan', PandanCrinkles);
photoMap.set('/crinkles/strawberry', StrawberryCrinkles);
photoMap.set('/crinkles/ube', UbeCrinkles);
photoMap.set('/seasonal-sweets/dulce-de-leche', DulceDeLeche);
photoMap.set('/tiramisu/classic', Tiramisu);
photoMap.set('/tiramisu/strawberry-matcha', StrawberryMatchaTiramisu);
photoMap.set('/chiffon-cakes/chocolate-oreo', OreoChiffon);
photoMap.set('/seasonal-sweets/banana-pudding', BananaPudding);
photoMap.set('/more-sweets/cinnamon-buns', CinnamonBun);
photoMap.set('/crinkles/strawberry-heart', HeartCrinkles);
photoMap.set('/seasonal-sweets/mango', MangoChiffon);

const photoMap2 = new Map();
photoMap2.set('/custom-cakes/heart', CustomHeart2);
photoMap2.set('/custom-cakes/standard', MangoChiffonCake2);
photoMap2.set('/chiffon-cakes/chocolate', ChocolateChiffon2);
photoMap2.set('/chiffon-cakes/chocolate-deluxe', ChocolateDeluxeChiffon2);
photoMap2.set('/chiffon-cakes/vanilla', VanillaChiffon2);
photoMap2.set('/chiffon-cakes/vanilla-berries', VanillaChiffonDeluxe2);
photoMap2.set('/chiffon-cakes/ube', UbeChiffon2);
photoMap2.set('/chiffon-cakes/mocha', MochaChiffon2);
// photoMap2.set('/chiffon-cakes/chocolate-oreo', 'empty');
photoMap2.set('/cupcakes/banana', BananaCupcake2);
photoMap2.set('/cupcakes/ube', UbeCupcake2);
photoMap2.set('/cupcakes/strawberry-cheesecake', StrawberryCupcake2);
photoMap2.set('/cupcakes/chocolate-oreo', ChocolateCupcake2);
// photoMap2.set('/cupcakes/mocha', 'empty');
photoMap2.set('/cupcakes/red-velvet', RedVelvetCupcake2);
photoMap2.set('/crinkles/chocolate', ChocolateCrinkles2);
photoMap2.set('/crinkles/pandan', PandanCrinkles2);
photoMap2.set('/crinkles/strawberry', StrawberryCrinkles2);
photoMap2.set('/crinkles/ube', UbeCrinkles2);
photoMap2.set('/seasonal-sweets/dulce-de-leche', DulceDeLeche2);
photoMap2.set('/tiramisu/classic', Tiramisu2);
photoMap2.set('/tiramisu/strawberry-matcha', StrawberryMatchaTiramisu2);
photoMap2.set('/chiffon-cakes/chocolate-oreo', OreoChiffon2);
photoMap2.set('/seasonal-sweets/banana-pudding', BananaPudding2);
photoMap2.set('/more-sweets/cinnamon-buns', CinnamonBuns);
photoMap2.set('/crinkles/strawberry-heart', HeartCrinkles);
photoMap2.set('/seasonal-sweets/mango', MangoChiffonSlice);

const photoMap3 = new Map();
photoMap3.set('/custom-cakes/heart', CustomHeart3);
photoMap3.set('/custom-cakes/standard', MangoChiffonCake3);
photoMap3.set('/chiffon-cakes/chocolate', ChocolateChiffon3);
photoMap3.set('/chiffon-cakes/chocolate-deluxe', ChocolateDeluxeChiffon3);
photoMap3.set('/chiffon-cakes/vanilla', VanillaChiffon3);
photoMap3.set('/chiffon-cakes/vanilla-berries', VanillaChiffonDeluxe3);
photoMap3.set('/chiffon-cakes/ube', UbeChiffon3);
photoMap3.set('/chiffon-cakes/mocha', MochaChiffon3);
// photoMap3.set('/chiffon-cakes/chocolate-oreo', 'empty');
photoMap3.set('/cupcakes/banana', BananaCupcake3);
photoMap3.set('/cupcakes/ube', UbeCupcake3);
photoMap3.set('/cupcakes/strawberry-cheesecake', StrawberryCupcake3);
photoMap3.set('/cupcakes/chocolate-oreo', ChocolateCupcake3);
// photoMap3.set('/cupcakes/mocha', 'empty');
photoMap3.set('/cupcakes/red-velvet', RedVelvetCupcake3);
photoMap3.set('/crinkles/chocolate', ChocolateCrinkles3);
photoMap3.set('/crinkles/pandan', PandanCrinkles3);
photoMap3.set('/crinkles/strawberry', StrawberryCrinkles3);
photoMap3.set('/crinkles/ube', UbeCrinkles3);
photoMap3.set('/seasonal-sweets/dulce-de-leche', DulceDeLeche3);
photoMap3.set('/tiramisu/classic', Tiramisu3);
photoMap3.set('/tiramisu/strawberry-matcha', StrawberryMatchaTiramisu3);
photoMap3.set('/chiffon-cakes/chocolate-oreo', OreoChiffon3);
photoMap3.set('/seasonal-sweets/banana-pudding', BananaPudding3);
photoMap3.set('/crinkles/strawberry-heart', HeartCrinkles);
photoMap3.set('/seasonal-sweets/mango', MangoChiffonSlice);

export { photoMap, photoMap2, photoMap3 };