import React, { useState, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';

import CartContext from '../contexts/CartContext';

import Redirect from '../mobileComponents/Redirect';
import OrderItem from '../mobileComponents/OrderItem';
import Popup from '../mobileComponents/Popup';

import Burger from '../../assets/Burger.svg';
import UpRightArrow from '../../assets/UpRightArrow.svg';
import UpRightArrowWhite from '../../assets/UpRightArrowWhite.svg';
import WhiteInstagram from '../../assets/WhiteInstagram.svg';
import WhiteShoppingBag from '../../assets/WhiteShoppingBag.svg';
import RedShoppingBag from '../../assets/RedShoppingBag.svg';

function FooterSection(props) {
    const { to, link, title, children } = props;
    return (
        <div className='frame column center align-items-start stretch' style={{ gap: '8rem' }}>
            {to ? <Redirect to={to} className='stretch'>
                <div className='frame center stretch'>
                    <p className='font-16 uppercase white frame flex-100'>{title}</p>
                    {link && <img className='link-arrow' src={UpRightArrowWhite}></img>}
                </div>
            </Redirect> : <div className='frame center stretch'>
                <p className='font-16 uppercase white frame flex-100'>{title}</p>
                {link && <img className='link-arrow' src={UpRightArrowWhite}></img>}
            </div>}
            {children}
        </div>
    );
}

function Footer(props) {
    const { cart, handleRemoveFromCart } = useContext(CartContext);
    const order = cart.items;

    const [open, setOpen] = useState(null);

    const [confirmDelete, setConfirmDelete] = useState(-1);

    const itemFrames = [];

    if (order.length < 1) {
        itemFrames.push(<div className='frame stretch center'>
            <p className='font-28 bold'>There are no items in your cart. Go to the menu to add sweets!</p>
        </div>);
    } else {
        let i = 0;
        for (const item of order) {
            const id = i;
            console.log('item: ', item.id);
            itemFrames.push(
                <OrderItem
                    item={item.item}
                    size={item.states.size}
                    flavor={item.states.flavor}
                    toppings={item.states.toppings}
                    frosting={item.states.frosting}
                    inscription={item.receipt.inscription}
                    inscriptionStyle={item.receipt.inscription_style}
                    colors={item.receipt.colors}
                    notes={item.receipt.notes}
                    price={item.receipt.price}
                    handleClickRemove={() => { setConfirmDelete(id); }}
                    type='footer' />
            );
            i++;
        }
    }

    const OpenMenuSection = <FooterSection title='Menu' to='/menu'>
        <div className='row-divider-1 white' />
        <div className='frame align-start justify-start stretch' style={{ gap: '24rem' }}>
            <div className='frame column align-start' style={{ gap: '8rem' }}>
                <Redirect to='/menu/chiffon-cakes'><p className='font-16 white align-start'>Chiffon Cakes</p></Redirect>
                <Redirect to='/menu/custom-cakes'><p className='font-16 white align-start'>Custom Cakes</p></Redirect>
                <Redirect to='/menu/cupcakes'><p className='font-16 white align-start'>Cupcakes</p></Redirect>
            </div>
            <div className='frame column align-start' style={{ gap: '8rem' }}>
                <Redirect to='/menu/crinkles'><p className='font-16 white align-start'>Crinkles</p></Redirect>
                <Redirect to='/menu/tiramisu'><p className='font-16 white align-start'>Tiramisu</p></Redirect>
                <Redirect to='/menu/seasonal-sweets'><p className='font-16 white align-start'>Seasonal Sweets</p></Redirect>
            </div>
        </div>
    </FooterSection>;

    const ClosedMenuSection = <FooterSection title='Menu' link to='/menu'></FooterSection>;

    const EventsSection = <FooterSection title='Events' link to='/events'></FooterSection>;

    const AboutSection = <FooterSection title='About the Chef' link to='/about'></FooterSection>;

    const SocialsSection = <div className='frame stretch justify-between' style={{ gap: '8rem' }}>
        <div className='column center stretch' style={{ gap: '8rem', padding: '0' }}>
            <a href='mailto:chefskascravings@gmail.com?subject=Customer Support'><p className='font-16 uppercase white align-start'>chefskascravings@gmail.com</p></a>
        </div>
        <Redirect to='https://www.instagram.com/chefskascravings/' external><img style={{ width: '22rem' }} src={WhiteInstagram}></img></Redirect>
    </div>;

    const CustomerServiceSection = <a href="mailto: help@chefskascravings.com?subject=Chefska's Cravings Customer Support"><p className='font-16 uppercase white align-start'>Customer Support</p></a>;

    const CondensedSection = <div className='frame center stretch justify-between'>
        <FooterSection title='About' link to='/about'></FooterSection>
        {/* {EventsSection} */}
        <Redirect to='https://www.instagram.com/chefskascravings/' external><img style={{ width: '22rem' }} src={WhiteInstagram}></img></Redirect>
        {/* {SocialsSection} */}
    </div>;

    const openComponent = (openState) => {
        switch (openState) {
            case 'cart':
                return <div className='frame column stretch center justify-start drawer true-white overflow-scroll' style={{ maxHeight: '65vh', maxWidth: '82vw', borderRadius: '2.5vw', gap: '8rem', padding: '2vw 4vw' }}>
                    <Redirect to='/checkout' className='stretch center'>
                        <div className='frame row justify-between' style={{ padding: '4rem 4rem 0' }}>
                            <p className='font-16 uppercase center'>My Cart</p>
                            <div className='frame row' style={{ gap: '4rem' }}>
                                <p className='font-16 uppercase center'>{order.length} item{order.length !== 1 ? 's' : ''}</p>
                                <img style={{ width: '24rem' }} src={UpRightArrow}></img>
                            </div>
                        </div>
                    </Redirect>
                    <div className='row-divider-1' />
                    <div className='frame column center justify-start overflow-scroll' style={{ gap: '4rem' }}>
                        {itemFrames}
                    </div>
                </div>;
            case 'dynamic':
                return <Routes>
                    <Route path='*' element={<div className='frame column stretch align-start justify-start drawer-children primary' style={{ minHeight: '11vw', maxWidth: '82vw', borderRadius: '2.5vw', gap: '8rem', padding: '4vw' }}>
                        {ClosedMenuSection}
                        <div className='row-divider-1 white' />
                        {SocialsSection}
                        <div className='row-divider-1 white' />
                        {CustomerServiceSection}
                    </div>} />
                    <Route path='/menu/*' element={<div className='frame column stretch align-start justify-start drawer-children primary' style={{ minHeight: '11vw', maxWidth: '82vw', borderRadius: '2.5vw', gap: '8rem', padding: '4vw' }}>
                        {OpenMenuSection}
                        <div className='row-divider-1 white' />
                        {CondensedSection}
                        <div className='row-divider-1 white' />
                        {CustomerServiceSection}
                    </div>} />
                </Routes>
            default:
                return null;
        }
    }

    return (
        <>
            <div className='frame column stretch fixed frosted-white drawer' style={{ zIndex: '12', width: '90vw', bottom: '2.5vw', left: '2.5vw', borderRadius: '5vw', gap: '2.5vw', padding: '2.5vw' }}>
                {openComponent(open)}
                <div className='frame row stretch center justify-start primary' style={{ height: '11vw', maxWidth: '82vw', borderRadius: '2.5vw', padding: '2vw 4vw' }}>
                    <div className='frame row center justify-start flex-100' style={{ gap: '8rem', padding: '0' }}
                        onClick={() => {
                            if (open !== 'dynamic') setOpen('dynamic');
                            else setOpen(null);
                        }}>
                        <img style={{ width: '24rem', height: '18rem' }} src={Burger} />
                        <Routes>
                            <Route index element={<p className='true-white uppercase font-20 semi-bold'>Explore</p>} />
                            <Route path='*' element={<p className='true-white uppercase font-20 semi-bold'>Menu</p>} />
                        </Routes>
                    </div>
                    <div className={'frame center ' + (open === 'cart' ? 'true-white' : 'primary')} style={{ gap: '4rem', padding: '4rem 8rem', borderRadius: '20rem', border: '2.5rem solid white' }}
                        onClick={() => {
                            if (open !== 'cart') setOpen('cart');
                            else setOpen(null);
                        }}>
                        <img style={{ width: '28rem', height: '28rem' }} src={open === 'cart' ? RedShoppingBag : WhiteShoppingBag} />
                        {order.length > 0 &&
                            <p className={'font-20 bold ' + (open === 'cart' ? 'primary' : '')} style={{ paddingRight: '4rem' }}>{order.length}</p>
                        }
                    </div>
                </div>
            </div>
            <div className='frame white' style={{ height: '25vw' }}></div>
            {confirmDelete >= 0 &&
                <Popup handleGoBack={() => setConfirmDelete(-1)}>
                    <OrderItem
                        item={order[confirmDelete].item}
                        size={order[confirmDelete].states.size}
                        flavor={order[confirmDelete].states.flavor}
                        toppings={order[confirmDelete].states.toppings}
                        frosting={order[confirmDelete].states.frosting}
                        inscription={order[confirmDelete].receipt.inscription}
                        inscriptionStyle={order[confirmDelete].receipt.inscription_style}
                        colors={order[confirmDelete].receipt.colors}
                        notes={order[confirmDelete].receipt.notes}
                        price={order[confirmDelete].receipt.price}
                        type='vert'
                    ></OrderItem>
                    <div className='frame center' style={{ gap: '16rem' }}>
                        <div className='frame primary inverted hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                            onClick={() => setConfirmDelete(-1)}>
                            <p className='bold font-24'>Cancel Delete</p>
                        </div>
                        <div className='frame primary hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                            onClick={() => {
                                handleRemoveFromCart(order[confirmDelete].id);
                                setConfirmDelete(-1);
                            }}>
                            <p className='bold font-24'>Confirm Delete</p>
                        </div>
                    </div>
                </Popup>
            }
        </>
    );
}

export default Footer;