import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import SessionContext from '../contexts/SessionContext';
import CartContext from '../contexts/CartContext';

import Redirect from '../components/Redirect';

import Scheduler from './Scheduler';
import { toShortFormat, toShorterFormat } from '../../functions/Datetimes';

import StraightLogo from '../../assets/StraightLogo.svg'

const cookies = new Cookies(null, { path: '/' });

function Header(props) {
    const { session } = useContext(SessionContext);
    const { cart } = useContext(CartContext);
    console.log('cart: ', cart);
    const location = useLocation();
    const [nextAvailability, setNextAvailability] = useState('');

    useEffect(() => {
        //get next availability
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/scheduler')
            .then((res) => res.json())
            .then((data) => {
                // console.log('DATA: ' + JSON.stringify(data));
                if (new Date(data[0].start_time).getTime() < new Date(data[1].start_time).getTime())
                    setNextAvailability(toShorterFormat(toShortFormat(new Date(data[0].start_time))));
                else
                    setNextAvailability(toShorterFormat(toShortFormat(new Date(data[1].start_time))));
            });
    }, []);

    return (
        <>
            <div className='frame column center stretch relative' style={{ top: '0', height: '60rem', zIndex: '20' }}>
                <Redirect to='/' className='frame primary center stretch mobile-logo'>
                    <img className='frame center' style={{ width: '244.5rem', height: '30rem' }} src={StraightLogo}></img>
                </Redirect>
                <Routes>
                    <Route path='/menu/*' element={<Scheduler />} />
                    <Route path='/checkout' element={<Scheduler />} />
                    <Route path='/checkout/cart' element={<Scheduler />} />
                </Routes>
            </div>
        </>
    );
}

export default Header;