import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../mobilePartials/Home';
import Header from '../mobilePartials/Header';
import Footer from '../mobilePartials/Footer';
import Menu from '../mobilePartials/Menu';
import Item from '../mobilePartials/Item';
import Checkout from '../mobilePartials/Checkout';
import PaymentComplete from '../mobilePartials/PaymentComplete';
import About from '../mobilePartials/About';
import Contact from '../mobilePartials/Contact';

export default function Desktop(props) {
    return (
        <>
            <Header />
            <Routes>
                <Route index element={<Home />} />
                <Route path='*' element={
                    <div className='frame flex-200 offwhite center'>
                        <p className='font-20 black'>404 Not Found :{'('}</p>
                    </div>
                } />
                <Route path='/menu' element={<Menu />}>
                    <Route path='/menu/:categoryName' element={<Menu />} />
                </Route>
                <Route path='/menu/:categoryName/:itemName' element={<Item />} />
                <Route path='/checkout/*' element={<Checkout />} />
                <Route path='/order-complete' element={<PaymentComplete />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
            </Routes>
            <Routes>
                <Route path='*' element={<Footer />} />
                <Route path='/checkout/*' element={<></>} />
            </Routes>
        </>
    )
}