import React, { useState } from 'react';

import Frame from '../components/Frame';

import Union from '../../assets/Union.svg';
import HorizontalLogo from '../../assets/HorizontalLogo.svg';
import About1 from '../../photos/About9.png';
import About2 from '../../photos/About2.jpeg';
import About3 from '../../photos/About3.jpg';
import About4 from '../../photos/About4.jpg';
import About5 from '../../photos/About5.jpg';
import About7 from '../../photos/About7.png';
import About8 from '../../photos/About8.jpeg';
import About10 from '../../photos/About10.png';

export default function About(props) {
    return (
        <div className='frame column justify-center align-start stretch true-white'>
            <div className='frame center stretch'>
                <img className='frame flex-100' src={About1} style={{ width: '50vw' }}></img>
                <div className='frame column justify-center align-start' style={{ gap: '24rem', padding: '40rem 80rem' }}>
                    <div className='frame column justify-center align-start' style={{ gap: '8rem' }}>
                        <p className='font-28 primary'>About</p>
                        <img className='about-logo' style={{ width: '606rem' }} src={HorizontalLogo}></img>
                    </div>
                    <p className='font-24'>In 2020, Chefska’s Cravings was founded by Adrianne Franchesca (Chef Cheska) in effort to connect and share her favorite home baked goods with others amidst the pandemic. We are an online small business based in San Leandro and Castro Valley, California, committed to serving high-quality, locally-sourced, authentic Filipino desserts to our local community.</p>
                </div>
            </div>
            <div className='frame center stretch' style={{ gap: '50rem', padding: '40rem 80rem', backgroundColor: '#FCC7C9' }}>
                <img className='frame flex-100' style={{ width: '327rem', height: '287rem', borderRadius: '20rem' }} src={About2}></img>
                <img className='about-union' style={{ width: '193rem', height: '188rem' }} src={Union}></img>
                <img className='frame frame flex-100' style={{ width: '327rem', height: '287rem', borderRadius: '20rem' }} src={About3}></img>
                <img className='about-union' style={{ width: '193rem', height: '188rem' }} src={Union}></img>
                <img className='frame frame flex-100' style={{ width: '327rem', height: '287rem', borderRadius: '20rem' }} src={About4}></img>
            </div>
            <div className='frame center align-items-start stretch' style={{ gap: '40rem', padding: '80rem 80rem' }}>
                <p className='frame flex-100 font-40 castoro'>Everything is made with love, crafted with care, and perfected with passion.</p>
                <p className='frame flex-100 font-24'>Each layer, each frosting swirl, and every decorative detail commemorates a story of our patrons’ celebration, joy, and personal expression. We promise to deliver the perfect centerpiece to capture your moments of love, connection, and shared memories.</p>
            </div>
            <img className='about-banner' style={{ width: '100vw', height: '356rem' }} src={About5}></img>
            <div className='frame center stretch true-white' style={{ padding: '40rem 80rem' }}>
                <p className='castoro font-24'>Go, eat your food with gladness, and drink your wine with a joyful heart, for God has already approved what you do. <span className='primary'>Ecclesiastes 9:7</span></p>
            </div>
            <div className='frame center stretch offwhite' style={{ gap: '50rem', padding: '40rem 80rem', backgroundColor: '#FBF8F6' }}>
                <div className='inline-block column center align-items-start stretch' style={{ gap: '36rem' }}>
                    <p className='font-40 castoro nowrap'>
                        Baking is more than culinary endeavor.
                        <br />
                        <span className='primary'>It’s an art form crafting your story with love.</span>
                    </p>
                    <div className='invisible' style={{ height: '36rem' }}></div>
                    <div className='frame'>
                        <p className='font-24 wrap flex-100' style={{ width: '0' }}>Love, care, and passion is baked into the heart of everything we do in both taste and sight. We aspire to satisfy all cravings: physical, emotional, and spiritual through acts of the giving high quality desserts that you and your loved ones love!</p>
                    </div>
                </div>
                <img className='about-video' style={{ width: '850rem', height: '503rem', borderRadius: '32rem' }} src={About7}></img>
            </div>
            <div className='frame column center stretch' style={{ gap: '32rem', padding: '80rem', backgroundColor: '#2E241C' }}>
                <div className='frame center stretch' style={{ gap: '80rem' }}>
                    <img className='about-section-picture' style={{ width: '409rem', height: '529rem', borderRadius: '938rem' }} src={About8}></img>
                    <div className='frame column align-items-start flex-100' style={{ gap: '24rem' }}>
                        <p className='font-48 castoro pink'>Meet the Chef*ska*</p>
                        <p className='font-24 white'>
                            “Hi, my name is Adrianne Franchesca, but you can call me Cheska!
                            <br /><br />
                            I was born and raised in the Philippines for 7 years before immigrating to the United States. My Filipino heritage greatly influences the flavor profiles of my desserts, drawing inspiration from beloved bakeries such as Red Ribbon and Goldilocks. I enjoy creating recipes from scratch and exploring a variety of flavors, designs, and presentation techniques. Not to mention the best part which is actually eating and trying out the desserts. I started Chefska’s Cravings to share the desserts I am most proud of, as well as my Filipino favorites: chocolate crinkles, mocha chiffon cake, and ube cupcakes.
                            <br /><br />
                            While baking is a passion of mine, the true delight comes from sharing my creations with those I cook for. Witnessing their smiles and enjoyment brings me immense joy and fulfillment. It's the heartwarming reactions that make every moment in the kitchen worthwhile.”
                        </p>
                    </div>
                </div>
                <div className='separator invisible'></div>
            </div>
            <div className='frame center stretch' style={{ gap: '80rem', padding: '80rem' }}>
                <div className='frame column' style={{ gap:'40rem' }}>
                    <p className='font-48 castoro'>Humble Beginnings</p>
                    <p className='font-24'>
                        At the age of 11, Cheska began baking brownies, cupcakes, and cakes from premade mixes, drawing inspiration from food challenge reality shows such as Cupcake Wars, Top Chef, and The Great British Baking Show. Her passion grew as she progressed into experimenting with homemade recipes to bake for family and close friends.
                        <br /><br />
                        Eight years later, the pandemic struck, providing her with more time and energy to try out different recipes and improve her cake decorating skills at home. Her family and close friends loved her food! They constantly asked her to start her own business. And BOOM! Chefska’s Cravings was born in the spring of 2020.
                        <br /><br />
                        Cheska is currently accepting online orders while working at Always Yours Bakery Cafe to polish her craft.  She credits Natalie, owner of Always Yours Bakery Cafe, for the opportunity to learn how to bake different pastries like macarons and choux, and adopting different techniques on decorating cakes.
                    </p>
                </div>
                <img className='about-collage' style={{ width: '794rem', height: '930rem' }} src={About10}></img>
            </div>
        </div>
    );
}